<template>
  <div>
    <b-card
      no-body
      class="mb-1"
    >
      <b-card-header class="pb-1">
        <h4 class="card-title font-weight-bolder">
          Document Folders
        </h4>
        <div class="d-flex align-items-center justify-content-end">

          <!-- Button create -->
          <b-button
            v-b-modal.modal-create-folder
            variant="info"
            class="mr-2"
          >
            <span class="text-nowrap">
              <feather-icon
                class="cursor-pointer"
                icon="PlusSquareIcon"
                size="16"
              />
              Create Folder
            </span>
          </b-button>

          <b-button
            variant="primary"
            class="mr-1"
            @click="() => $router.push({name: 'apps-documents-folder-view'})"
          >
            <span class="text-nowrap">
              <feather-icon
                class="cursor-pointer"
                icon="ListIcon"
                size="16"
              />
              List View
            </span>
          </b-button>
        </div>
      </b-card-header>
    </b-card>

    <b-card>

      <!-- search input -->
      <!-- <b-input-group class="mb-1">
      <b-input-group-prepend is-text>
        <feather-icon icon="SearchIcon" />
      </b-input-group-prepend>
      <b-form-input
        v-model.lazy="searchword"
        placeholder="Search..."
        @input="search"
      />
    </b-input-group> -->

      <b-row>
        <b-col>
          <b-button
            class="float-right"
            variant="danger"
            :disabled="readonly"
          >
            Delete
          </b-button>
        </b-col>
      </b-row>

      <!-- tree -->
      <v-tree
        ref="tree"
        :can-delete-root="false"
        :data="treeData"
        :draggable="true"
        :tpl="tpl"
        :halfcheck="false"
        :multiple="true"
      />

    </b-card>

  </div>
</template>

<script>
// import { VTree } from 'vue-tree-halower'
import {
  BCardHeader,
  BCard,
  BButton,
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  components: {
    // VTree,
    BCardHeader,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  data() {
    return {
    //   codeSearch,
      readonly: true,
      searchword: '',
      treeData: [
        {
          title: 'Root',
          expanded: true,
          nocheck: true,
          children: [
            {
              title: 'List of booked tickets',
              expanded: true,
              children: [
                {
                  title: 'Books tickets 1',
                },
                {
                  title: 'Books tickets 2',
                },
                {
                  title: 'Books Tickets 3',
                },
              ],
            },
          ],
        },
      ],
    }
  },
  // watch: {
  //   getCheckedNodes() {
  //     this.readonly = false
  //   },
  // },
  methods: {
    // search() {
    //   this.$refs.tree.searchNodes(this.searchword)
    // },
    tpl(...args) {
      const {
        0: node,
        // 2: parent,
        // 3: index
      } = args
      let titleClass = node.selected ? 'node-title node-selected' : 'node-title'
      if (node.searched) titleClass += ' node-searched'
      return <span>

          <span class={titleClass} domPropsInnerHTML={node.title} onClick={() => {
            this.$refs.tree.nodeSelected(node)
          }}></span>
          </span>
          // <span class="tree-expand" onClick={() => this.$refs.tree.addNode(node, { title: 'sync node' })}>+</span>
          // <button class="btn-delete text-danger border-0 cursor-pointer" onClick={() => this.$refs.tree.delNode(node, parent, index)}>delete</button>
          // <button class="btn-async text-warning border-0 cursor-pointer mr-1" onClick={() => this.asyncLoad(node)}>async</button>
    },
    // async asyncLoad(node) {
    //   const { checked = false } = node
    //   this.$set(node, 'loading', true)
    //   const pro = new Promise(resolve => {
    //     setTimeout(resolve, 2000, ['async node1', 'async node2'])
    //   })
    //   this.$refs.tree.addNodes(node, await pro)
    //   this.$set(node, 'loading', false)
    //   if (checked) {
    //     this.$refs.tree.childCheckedHandle(node, checked)
    //   }
    // },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/tree.scss";
</style>

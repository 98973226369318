var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    staticClass: "mb-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    staticClass: "pb-1"
  }, [_c('h4', {
    staticClass: "card-title font-weight-bolder"
  }, [_vm._v(" Document Folders ")]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-end"
  }, [_c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-create-folder",
      modifiers: {
        "modal-create-folder": true
      }
    }],
    staticClass: "mr-2",
    attrs: {
      "variant": "info"
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "PlusSquareIcon",
      "size": "16"
    }
  }), _vm._v(" Create Folder ")], 1)]), _c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click() {
        return _vm.$router.push({
          name: 'apps-documents-folder-view'
        });
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "ListIcon",
      "size": "16"
    }
  }), _vm._v(" List View ")], 1)])], 1)])], 1), _c('b-card', [_c('b-row', [_c('b-col', [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "danger",
      "disabled": _vm.readonly
    }
  }, [_vm._v(" Delete ")])], 1)], 1), _c('v-tree', {
    ref: "tree",
    attrs: {
      "can-delete-root": false,
      "data": _vm.treeData,
      "draggable": true,
      "tpl": _vm.tpl,
      "halfcheck": false,
      "multiple": true
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }